:global {
    //PC
    $xsFs: 16px;
    $smFs: 20px;
    $mdFs: 26px;
    $lgFs: 34px;
    $xlFs: 46px;
    $xxlFs: 50px;   //service滚动数字
    $xxxlFs: 98px;   //about滚动数字

    //web
    $mxsFs:12px;
    $msmFs:14px;
    $mmdFs:16px;
    $mlgFs:20px;
    $mxlFs:28px;
    img{
        image-rendering: -moz-crisp-edges; /* Firefox */     
        image-rendering: -o-crisp-edges; /* Opera */      
        image-rendering: crisp-edges; 
        -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
        image-rendering: -webkit-optimize-contrast; /*Webkit (non-standard naming) */
    }
    .item1{
        padding-left: 0 !important;
        margin-right: 2.5%!important;
        width: 31%!important;
    }
    .item2{
        margin-right: 2.5%!important;
    }
    .item3{
        width: 31%!important;
        padding-right: 0!important;
    }
    input::-webkit-input-placeholder {
        color: #111;
        font-size: 16px !important;
    }
    input {
        color: #000;
        font-size: 16px !important;
    }

    .out {
        width: 80%;
        display: block;
        margin: 0 auto;
        position: relative;
        max-width: 1380px;
    }

    .website_container {
        padding-top: 66px;
    }

    .inner {
        border: 1px solid red;
    }

    body {
        background-color: #f6f6f6 !important;
        color: #000 !important;
    }

    html {
        font-size: 16px;
    }

    .mid {
        position: absolute;
        top: 50%;
        left: 50%;
        right: 50%;
        transform: translate(-50%, -50%);
    }

    .wait_loading {
        position: fixed !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .full_img {
        width: 100%;
    }

    .swiper-pagination-bullet {
        height: 4px !important;
        width: 38px !important;
        border-radius: 0 !important;
    }

    .swiper-pagination-bullet:not(:first-of-type) {
        margin-left: 5px;
    }

    .swiper-pagination-bullet-active {
        width: 68px !important;
    }

    .swiper-container-horizontal > .swiper-scrollbar {
        left: 0 !important;
        width: 90% !important;
    }

    .pagination-white > .swiper-pagination-bullet {
        background-color: #fff !important;
    }

    .pagination-black > .swiper-pagination-bullet {
        background-color: #000 !important;
    }

    .pagination-white > .swiper-pagination-bullet-active {
        background-color: #fff !important;
    }

    .pagination-black > .swiper-pagination-bullet-active {
        background-color: #000 !important;
    }

    .ant-timeline-item {
        padding-bottom: 3rem !important;
    }

    .no_scroll {
        overflow-y: hidden;
        padding-top: 66px;
    }

    .guide_mask {
        position: fixed;
        border: 1px solid red;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        background-color: rgba(0, 0, 0, .7);
    }

    /* Sweep To Right */
    .hvr-sweep-to-right {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        position: relative;
        -webkit-transition-property: color;
        transition-property: color;
        -webkit-transition-duration: .3s;
        transition-duration: .3s;
    }
    .hvr-sweep-to-right:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #61ac2a;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: .3s;
        transition-duration: .3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }
    .hvr-sweep-to-right:hover,
    .hvr-sweep-to-right:focus,
    .hvr-sweep-to-right:active {
        color: #fff;
    }
    .hvr-sweep-to-right:hover:before,
    .hvr-sweep-to-right:focus:before,
    .hvr-sweep-to-right:active:before {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }

    @media screen and (max-width: 1000px) {
        //mid
        body,
        html {
            font-size: 14px !important;
        }
    }

    @media screen and (max-width: 600px) {
        //web

        .website_container {
            padding-top: 15vw;
        }

        .website_container {
            position: fixed;
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .out {
            width: 90%;
        }
        .no_scroll {
            overflow-y: hidden;
            padding-top: 15vw;
        }
    }

    .colorGreen {
        font-weight: bold !important;
        color: #449f02;
    }
    .color111 {
        color: #111;
    }

    //全局字体样式设置
    .fs16,.fs16.selectFs {
        font-size: $xsFs;
        line-height: 30px;
    }
    .fs18 {
        line-height: 32px;
    }
    .fs20 {
        font-size: $smFs;
        line-height: 36px;
    }
    .fs22 {
        font-size: $smFs;
        line-height: 40px;
    }
    .ttg_fs20 {
        font-weight: normal !important;
        font-size: $smFs;
        line-height: 40px;
    }
    .fs50 {
        font-size: $xxlFs;
    }
    .ttg_fs46 {
        font-weight: normal !important;
        font-size: $xlFs;
    }
    .nfs28 {
        font-size: $mdFs !important;
        color: #000 !important;
    }
    .nfs20 {
        font-size: $smFs !important;
        color: #111 !important;
        line-height: 40px !important;
    }
    .nfs16 {
        font-size: $xsFs !important;
        color: #111 !important;
        line-height: 30px !important;
    }
    .ntfs16 {
        font-size: $xsFs !important;
        line-height: 30px !important;
    }
    .nfs46 {
        font-size: $xlFs !important;
    }

    .onfs98 {
        font-size: $xxxlFs !important;
    }
    .onfs50 {
        font-size: $xxlFs !important;
    }
    .onfs20 {
        font-size: $smFs !important;
        line-height: 40px !important;
    }
    .onfs28 {
        font-size: $mdFs !important;
    }
    .onfs16,.oldfs16{
        font-size: $xsFs !important;
    }
    .oldfs26 {
        font-size: $mdFs;
    }
    .ttg_fs34 {
        font-size: $lgFs;
    }

    @media screen and (max-width: 1000px) {
        .item1{
            width: initial!important;
            padding-left: initial !important;
            margin-right: initial!important;
        }
        .item2{
            margin-right: initial!important;
        }
        .item3{
            width: initial!important;
            padding-right: initial!important;
        }

        .swiper-pagination-bullet {
            width: 20px !important;
            height: 2px !important;
            border-radius: 0 !important;
        }
        .swiper-pagination-bullet-active{
            width: 30px !important;
            height: 2px !important;
        }
        .fs16 {
            font-size: $msmFs !important;
            line-height: 26px;
        }
        .oldfs16 {
            font-size: $msmFs !important;
        }
        .fs16.selectFs {
            font-size: $mxsFs !important;
            line-height: 22px;
        }
        .fs18,.fs20 {
            font-size: $msmFs !important;
            line-height: 26px;
        }
        .ttg_fs20 {
            line-height: unset;
        }
        .fs22,.ttg_fs20,.oldfs26 {
            font-size: $mmdFs !important;
        }
        .ttg_fs34 {
            font-size: $mlgFs !important;
        }
        .fs50,.ttg_fs46 {
            font-size: $mxlFs !important;
        }
        .nfs20,
        .nfs16,
        .ntfs16 {
            line-height: 1.8 !important;
        }
        
        .webfs16{
            font-size: $mmdFs !important;
        }
        .webfs20{
            font-size: $mlgFs !important;
        }
        .lh28{
            line-height: 28px !important;
        }
    }

    .ttgFs16{
        font-size: $xsFs !important;
    }
    .ttgFs20{
        font-size: $smFs !important;
    }
    .ttgFs26{
        font-size: $mdFs !important;
    }
    .ttgFs34{
        font-size: $lgFs !important;
    }
    .ttgFs46{
        font-size: $xlFs !important;
    }
    .ttgFs50{
        font-size: $xxlFs !important;
    }
    .ttgFs98{
        font-size: $xxxlFs !important;
    }
    @media screen and (max-width: 1000px) {
        .ttgFs16{
            font-size: $msmFs !important;
        }
        .ttgFs20,.ttgFs26{
            font-size: $mmdFs !important;
        }
        .ttgFs34{
            font-size: $mlgFs !important;
        }
        .ttgFs46,.ttgFs50,.ttgFs98{
            font-size: $mxlFs !important;
        }
    }
}
