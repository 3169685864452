.nav_container {
    position: fixed;
    display: flex;
    top: 0;
    z-index: 600;
    width: 100%;
    height: 66px;
    border-bottom: 3px solid #f2f2f2;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    animation: opAn .3s ease-in forwards .25s;
}
@keyframes navOp {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.nav_out {
    position: absolute !important;
    display: flex !important;
    left: 50%;
    top: 50%;
    width: 1080px !important;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    transform: translate(-25%,-50%);
}
.logoBox{
    position: relative;
    z-index: 99;
    width: 80%;
    height: 100%;
    max-width: 1380px;
    margin: 0 auto;
}
.nav_left {
    position: absolute;
    left: 10vw;
    top: 50%;
    width: 80px;
    transform: translateY(-50%);
}
@media screen and (min-width:1725px) {
    .nav_left {
        left: 50%;
        margin-left: -610px;
        transform: translate(-100%,-50%);
    }
}
.nav_left > img {
    width: 100%;
}
.nav_logo{
    z-index: 999;
}
.nav_item {
    position: relative;
    display: inline-block;
    overflow: hidden;
    font-family: 'Helvetica neue' sans-serif;
    font-size: 20px;
    color: #000;
    vertical-align: middle;
    white-space: nowrap;
    text-decoration: none;
    text-overflow: ellipsis;
    line-height: 1.8;
    cursor: pointer;
}
.nav_item:hover:after {
    opacity: 1;
    animation: trace-in .25s ease-out forwards 0s;
}
.nav_itemCommon:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #000;
    transform: translateX(calc(-100% - .1rem));
    animation: trace-out .25s ease-in-out forwards 0s;
}
.nav_itemActive:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #000;
}
@keyframes trace-in {
    0% {
        transform: translate3d(-101%, 0, 0);
    }
    to {
        transform: translateZ(0);
    }
}
@keyframes trace-out {
    0% {
        transform: translateZ(0);
    }
    to {
        transform: translate3d(101%, 0, 0);
    }
}
.nav_mid {
    display: flex;
    width: 50%;
    text-align: center;
    white-space: nowrap;
    justify-content: space-between;
}
.nav_right {
    position: absolute;
    top: 50%;
    right: 0;
    width: 12rem;
    height: 30px;
    white-space: nowrap;
    cursor: pointer;
    transform: translateY(-50%);
}
.language {
    display: inline-block;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 600;
    vertical-align: top;
    white-space: nowrap;
}
.menu {
    position: absolute;
    display: none;
    top: 50%;
    right: 0;
    z-index: 999;
    transform: translate(-50%, -50%);
}
.menu > i {
    font-size: 20px !important;
}
.nav_mask {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    background-color: rgba(0, 0, 0, .5);
}
.mobile_link {
    position: relative;
    width: 100%;
}
.mobile_link > div {
    display: block;
    width: 100%;
    height: 65px;
    border-top: 1px solid #ececec;
    font-size: 14px;
    color: #000;
    text-align: center;
    letter-spacing: 0;
    line-height: 65px;
    box-sizing: border-box;
}
.mobile_search_out {
    position: relative;
    width: 100%;
    height: 74px;
}
.moblie_search_inner {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 38px;
    background-color: rgba(236, 236, 236, .4);
    border-radius: 5px;
    transform: translateY(-50%);
}
.search2 {
    position: absolute;
    top: 50%;
    right: 5%;
    font-size: 16px !important;
    color: #000;
    opacity: 1;
    transform: translateY(-50%);
}
.moblie_nav {
    position: relative;
    display: none;
    width: 100%;
    background-color: #fff;
}
.searchInput {
    position: absolute;
    height: 100%;
    width: 100%;
    padding-left: 3%;
    background-color: transparent;
}
.pcInput {
    display: none;
    height: 100%;
    width: 100%;
    padding-left: 5%;
    padding-right: 15%;
    margin-right: 5%;
    background-color: rgba(236, 236, 236, .4);
    font-size: 14px;
    border-radius: 5px;
    box-sizing: border-box;
    transform-origin: right center;
    animation: inputShow .3s linear forwards;
}
.search {
    position: absolute;
    top: 50%;
    right: 5%;
    font-size: 16px !important;
    transform: translateY(-50%);
}
@keyframes inputShow {
    from {
        opacity: 0;
        transform: scale(0);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
@media screen and (max-width: 1000px) {
    .nav_out{
        position: absolute !important;
        left: unset !important;
        top: 50% !important;
        width: 100% !important;
        transform: translateY(-50%) !important;
    }
    .nav_container {
        width: 100%;
        height: 66px;
        border-bottom: none;
        text-align: inherit;
    }
    .nav_item {
        display: block;
        width: 100%;
        text-align: center;
    }
    .nav_item:after {
        display: none !important;
    }
    .moblie_nav {
        position: absolute;
        display: block;
        width: 100%;
        bottom: 0;
        background: #fff;
        transform: translateY(100%);
    }
    .nav_mid,.nav_right {
        display: none;
    }
    .nav_left {
        left: 10%;
        width: 70px;
    }
    .menu {
        display: block;
    }
    .menu{
        right: 10%;
        transform: translateY(-50%);
    }
    .nav_item {
        opacity: 1 !important;
    }
    .nav_out {
        display: block !important;
    }
    .nav_itemCommon:after {
        content: '';
        display: none;
    }
    .nav_itemActive:before {
        content: '';
        display: none;
    }
}
@media screen and (max-width: 600px){
    .nav_left{
        left: 5%;
    }
    .menu{
        right: 5%;
    }
    .nav_container{
        height: 15vw;
    }
}
@media screen and (min-width: 1001px){
    .moblie_nav,.nav_mask{
        display: none !important;
    }
}
