.mask_out{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999999;
    background-color: rgba(1,1,1,.8);
    animation: showIn .3s linear forwards;
}
.img_mid{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 22rem;
    transform: translate(-50%,-50%);
}
@keyframes showIn {
    from{
        opacity: 0;    
    }
    to{
        opacity: 1;
    }
}

