@font-face {
  font-family: "fontNumber";
  src: url("./static/fonts/number.woff");
}

*{
  font-family: "Helvetica Neue", Helvetica, "Microsoft YaHei" , sans-serif,Arial, Roboto, DroidSans, "Droid San", Noto !important;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
body {
  margin: 0;
  user-select: none;
}

input,
textarea,
select {
  border-style: none;
  border-width: 0;
  border-color: transparent;
  outline-width: 0;
  outline-style: none;
  outline-color: transparent;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

input {
  -webkit-appearance: none;
  appearance: none;
}

html,
body {
  height: 100%;
}
