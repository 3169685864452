@font-face {
  font-family: "iconfont"; /* Project id 1884954 */
  src: url('iconfont.woff2?t=1629107820837') format('woff2'),
       url('iconfont.woff?t=1629107820837') format('woff'),
       url('iconfont.ttf?t=1629107820837') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon_sousuo:before {
  content: "\e612";
}

.icon_jian:before {
  content: "\e619";
}

.icon_douyin:before {
  content: "\e8db";
}

.icon_close:before {
  content: "\e678";
}

.icon_xiala:before {
  content: "\e603";
}

.icon_jia:before {
  content: "\e715";
}

.icon_xinfeng:before {
  content: "\e640";
}

.icon_weixin:before {
  content: "\e602";
}

.icon_in:before {
  content: "\e6c1";
}

.icon_weibo:before {
  content: "\e608";
}

.icon_right:before {
  content: "\e600";
}

.icon_caidan:before {
  content: "\e660";
}

.icon_search:before {
  content: "\e639";
}

